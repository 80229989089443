'use strict';
(function(){

class FindingImageSlideshowCtrl {
  constructor($scope, options, usSpinnerService) {
    this.usSpinnerService = usSpinnerService;
    this.options = options;
    this.finding = options.finding;
    this.activeImage = [];
    this.activeImage[options.activeIndex]= {
      active : true
    };
    // this.slideInterval = 5000;
    this.noWrapSlides = true;
  }

  slideNext() {
    if (this.options.activeIndex < this.finding.images.length-1) {
      this.options.activeIndex++;
      this.activeImage[this.options.activeIndex]= {
        active : true
      };
    }
  }

  slidePrev() {
    if (this.options.activeIndex > 0) {
      this.options.activeIndex--;
      this.activeImage[this.options.activeIndex]= {
        active : true
      };
    }
  }
  
  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .controller('FindingImageSlideshowCtrl', FindingImageSlideshowCtrl);

})();
